@import url("../../variables.css");

.section--rssfeed-box {
}

.section--rssfeed-box .inner {
}

.section--rssfeed-box .wrapper {}

.section--rssfeed-box .rssfeed-box-title {
    font-size: 30px;
    color: var(--base-text);
    margin: 50px 0;
}

.section--rssfeed .rssfeed-box-content {
    overflow-y: scroll;
    height: 20em;
}

@media screen and (max-width: 1380px) {
    .section--rssfeed-box {
        padding: 90px 20px 0;
    }

    .section--rssfeed-box .rssfeed-box-title {
        font-size: 30px;
    }

    .section--rssfeed-box .rssfeed-box-subtitle {
        font-size: var(--subtitle-font-size);
    }

    .section--rssfeed-box .rssfeed-box-content {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .section--rssfeed-box .rssfeed-box-title {
        text-align: center;
        font-size: var(--subtitle-font-size);
        font-weight: 500;
    }

    .section--rssfeed-box .rssfeed-box-subtitle {
        text-align: center;
    }

    .section--rssfeed-box .rssfeed-box-content {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}
