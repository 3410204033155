@import url("../../variables.css");

.section--rssfeed {
    margin: 50px 0;
    padding: 110px 150px 0 150px;
}

.section--rssfeed .inner {
    max-width: 1600px;
    margin: 0 auto;
}

.section--rssfeed .wrapper {}

.section--rssfeed .rssfeed-title {
    font-size: var(--title-font-size);
    color: var(--base-text);
}

.section--rssfeed .rssfeed-content {
    margin: 50px 0px 0 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 50%);
    column-gap: 0%;
}

@media screen and (max-width: 1380px) {
    .section--rssfeed {
        padding: 90px 20px 0;
    }

    .section--rssfeed .rssfeed-title {
        font-size: var(--title-font-size-large);
    }

    .section--rssfeed .rssfeed-subtitle {
        font-size: var(--subtitle-font-size-large);
    }

    .section--rssfeed .rssfeed-content {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .section--rssfeed .rssfeed-title {
        text-align: center;
        font-size: var(--title-font-size-medium);
        font-weight: 500;
    }

    .section--rssfeed .rssfeed-subtitle {
        text-align: center;
    }

    .section--rssfeed .rssfeed-content {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}
