@import url("../../variables.css");

footer {
    padding: 0 20px;
    margin: 30px 0;
}
footer .inner {
    max-width: 1600px;
    margin: 0 auto;
}
footer .footer-content {
    font-size: var(--content-font-size);
    text-align: center;
    color: var(--base-text);
}
