@import url("../../variables.css");

.work-page {
    background-color: var(--card-background-color);
    border-color: var(--border-color);
    border-width: 10px;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 13px 13px 6px var(--box-shadow-color);
    padding: 30px;
    margin: 120px 100px 30px 100px;
}

.work-page:hover {
    transition: 0.3s ease-out;
    box-shadow: 0 4px 8px var(--box-shadow-color-hover);
}

.work-page .work-page-img img {
    display: block;
    max-width: 250px;
    height: auto;
    margin: 0 auto;
}

.work-page .work-page-title {
    margin-top: 20px;
    font-size: var(--subtitle-font-size);
    color: var(--base-text);
    font-weight: 500;
    text-align: center;
}

.work-page .work-page-desc {
    line-height: 1.4;
    margin-top: 20px;
    font-size: var(--content-font-size);
    text-align: center;
    color: var(--base-text);
    font-weight: 300;
}

@media screen and (max-width: 1380px) {
    .work-page {
        margin-top: 100px;
    }

    .work-page .work-page-title {
        font-size: 25px;
    }

    .work-page .work-page-desc {
        font-size: 20px;
    }
}

@media screen and (max-width: 980px) {
    .work-page {
        margin-top: 80px;
    }
}

@media screen and (max-width: 768px) {
    .work-page {
        margin-top: 100px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .work-page .work-page-img img {
        max-width: 180px;
    }
}

@media screen and (max-width: 300px) {
    .work-page {
        margin-top: 80px;
    }
}
