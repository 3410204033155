@import url("../../variables.css");

#topButton {
    visibility: hidden;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: var(--button-color);
    color: var(--button-text-color);
    cursor: pointer;
    padding: 20px 15px;
    border-radius: 15px;
    transition: 0.3s ease-in-out;
    font-size: var(--content-font-size);
}
#topButton:hover {
    transform: translateY(-8px);
    transition: 0.3s ease 0s;
}

@media screen and (max-width: 768px) {
    #topButton {
        display: none;
    }
}
