@import url("../../variables.css");

.news-card {
    border-radius: 3px;
    border-color: var(--border-ucolor);
    border-top-style: solid;
    border-bottom-style: solid;
    border-width: 3px;
    margin-bottom: -3px;
    padding-top: -100px;
}
