@import url("../../variables.css");

.section--work {
    margin-top: 50px;
    padding: 110px 150px 0 150px;
}
.section--work .inner {
    max-width: 1600px;
    margin: 0 auto;
}
.section--work .work-title {
    font-size: var(--title-font-size);
    color: var(--base-text);
}
.section--work .work-subtitle {
    margin-top: 50px;
    font-size: var(--subtitle-font-size);
    color: var(--base-text);
    font-weight: 300;
}
.section--work .work-content {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 32%);
    column-gap: 2%;
}

@media screen and (max-width: 1380px) {
    .section--work {
        padding: 90px 20px 0;
    }
    .section--work .work-title {
        font-size: var(--title-font-size-large);
    }
    .section--work .work-subtitle {
        font-size: var(--subtitle-font-size-large);
    }
    .section--work .work-content {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .section--work .work-title {
        text-align: center;
        font-size: var(--title-font-size-medium);
        font-weight: 500;
    }
    .section--work .work-subtitle {
        text-align: center;
    }
    .section--work .work-content {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}
