:root {

    --gmail-color: #ea4335;
    --x-color: #89909C;
    --pixiv-color: #0096FA;
    --shop-color: #FC4D50;
    --homepage-color: #D8613F;
    --link-color: #4ABDF8;
    --box-shadow-color: rgba(0, 0, 0, 0.2);
    --box-shadow-color-dark: rgba(0, 0, 0, 0.3);
    --box-shadow-color-hover: rgba(38, 38, 38, 0.2);
    --main-title-color: #EC4AB7;
    --border-color: #F9DFF8;
    --border-ucolor: #DBEFFD;
    --base-text-hover: #ffffff;
    --icon-color: #c1c2c3;
    --icon-button-color: #ffffff;
    --header-logo-color: #888888;
    --news-image-color: #f1f1f1;
    --icon-background-color: #000000;

    --title-font-size: 60px;
    --subtitle-font-size: 25px;
    --content-font-size: 20px;
    --title-font-size-large: 45px;
    --title-font-size-medium: 32px;
    --subtitle-font-size-large: 20px;

    @media (prefers-color-scheme: light) {
        --background-color: #F6F6F6;
        --base-text: #4D434B;
        --card-background-color: #ffffff;
        --button-text-color: #f1f2f3;
        --button-color: #38393a;
    }
    @media (prefers-color-scheme: dark) {
        --background-color: #38393a;
        --base-text: #ffffff;
        --card-background-color: #2F3232;
        --button--text-color: #38393a;
        --button-color: #f1f2f3;
    }
}
