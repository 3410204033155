@import url("../../variables.css");

.section--contact {
    margin: 50px 0;
    padding: 110px 150px 0 150px;
}
.section--contact .contact-title {
    max-width: 1600px;
    margin: 0 auto;
    font-size: var(--title-font-size);
    color: var(--base-text);
}
.section--contact .inner {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
}
.section--contact .wrapper-inner {
    flex: 85% 1;
}
.section--contact .contact-subtitle {
    font-size: var(--subtitle-font-size);
    color: var(--base-text);
    margin-top: 50px;
    font-weight: 300;
}
.section--contact .contact-introduce {
    margin-top: 50px;
    font-size: var(--subtitle-font-size);
    font-weight: 500;
    color: var(--base-text);
}
.section--contact .social-links {
    margin-top: 50px;
}
.section--contact .profile-img {
    flex: 15% 1;
    text-align: right;
}
.section--contact .profile-img img {
    max-width: 250px;
    height: auto;
    border: 5px solid var(--border-color);
    border-radius: 30px;
    box-shadow: 0px 5px 12px 5px var(--box-shadow-color-dark)
}

.section--contact .profile-img img:hover {
    transition: 0.2s ease-out;
    box-shadow: 0 4px 8px var(--box-shadow-color-hover);
}

/* 装飾 */
.section--contact .contact-introduce a {
    color: var(--base-text-hover);
    background: var(--main-title-color);
    padding: 10px 30px;
    display: inline-block;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.5s ease-out;
}
.contact-introduce > a:hover {
    background-color: var(--icon-background-color);
}

@media screen and (max-width: 1380px) {
    .section--contact {
        padding: 90px 20px 0;
    }
    .section--contact .contact-title {
        font-size: var(--title-font-size-large);
    }
    .section--contact .contact-subtitle {
        font-size: var(--subtitle-font-size-large);
    }
}

@media screen and (max-width: 768px) {
    .section--contact .contact-title {
        font-size: var(--title-font-size-medium);
        font-weight: 500;
        text-align: center;
    }
    .section--contact .inner {
        display: block;
        margin-bottom: 40px;
    }
    .section--contact .profile-img {
        margin-top: 30px;
        text-align: center;
    }
    .section--contact .wrapper-inner {
        text-align: center;
    }
}
