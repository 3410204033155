@import url("../../variables.css");

.section--staff {
    margin-top: 50px;
    padding: 110px 150px 0 150px;
}
.section--staff .inner {
    max-width: 1600px;
    margin: 0 auto;
}
.section--staff .staff-title {
    font-size: var(--title-font-size);
    color: var(--base-text)
}
.section--staff .staff-cards {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 32%);
    column-gap: 2%;
}

.section--staff .staff-cards img {
    max-width: 150px;
    height: auto;
    border: 5px solid var(--border-color);
    border-radius: 100%;
    box-shadow: 0px 5px 12px 5px var(--box-shadow-color-dark);
    margin: 15px 0px 15px 0px;
}

@media screen and (max-width: 1380px) {
    .section--staff {
        padding: 90px 20px 0;
    }
    .section--staff .staff-title {
        font-size: var(--title-font-size-large);
    }
    .section--staff .staff-cards {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .section--staff .staff-title {
        font-size: var(--title-font-size-medium);
        text-align: center;
        font-weight: 500;
    }
    .section--staff .staff-cards {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}
