@import url("../../variables.css");

.section--gallery {
    margin: 50px 0;
    padding: 110px 150px 0 150px;
}

.section--gallery .inner {
    max-width: 1600px;
    margin: 0 auto;
}

.section--gallery .wrapper {}

.section--gallery .gallery-title {
    font-size: var(--title-font-size);
    color: var(--base-text);
}

.section--gallery .gallery-content {
    margin: 50px 0px 0 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 25%);
    column-gap: 0%;
}

.section--gallery .gallery-content img {
    width: 100%;
    height: auto;
    cursor: pointer;
    opacity: 1;
    transition: 0.5s ease-out;
    margin: 0 auto;
}

.section--gallery .gallery-content img:hover {
    transition: 0.5s ease-out;
    opacity: 0.2;
}

@media screen and (max-width: 1380px) {
    .section--gallery {
        padding: 90px 20px 0;
    }

    .section--gallery .gallery-title {
        font-size: var(--title-font-size-large);
    }

    .section--gallery .gallery-subtitle {
        font-size: var(--subtitle-font-size-large);
    }

    .section--gallery .gallery-content {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .section--gallery .gallery-title {
        text-align: center;
        font-size: var(--title-font-size-medium);
        font-weight: 500;
    }

    .section--gallery .gallery-subtitle {
        text-align: center;
    }

    .section--gallery .gallery-content {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}
