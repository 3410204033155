@import url("../../variables.css");

.work-card {
    background-color: var(--card-background-color);
    border-color: var(--border-color);
    border-width: 10px;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 13px 13px 6px var(--box-shadow-color);
    padding: 30px;
    margin-bottom: 30px;
}
.work-card:hover {
    transition: 0.3s ease-out;
    box-shadow: 0 4px 8px var(--box-shadow-color-hover);
}
.work-card .work-link {
    text-decoration: none;
}

.work-card .work-link:hover {
}

.work-card .work-card-img img{
    display: block;
    max-width: 250px;
    height: auto;
    margin: 0 auto;
}
.work-card .work-card-title {
    margin-top: 20px;
    font-size: var(--subtitle-font-size);
    color: var(--base-text);
    font-weight: 500;
    text-align: center;
}
.work-card .work-card-desc {
    line-height: 1.4;
    margin-top: 20px;
    font-size: var(--content-font-size);
    text-align: center;
    color: var(--base-text);
    font-weight: 300;
}

@media screen and (max-width: 1380px) {
    .work-card .work-card-title {
        font-size: 25px;
    }
    .work-card .work-card-desc {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    .work-card .work-card-img img {
        max-width: 180px;
    }
} 
