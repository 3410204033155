@import url("../../variables.css");

.section--news {
    padding: 110px 150px 0 150px;
    position: relative;
    margin-top: 50px;
}

.section--news .inner {
    max-width: 1600px;
    display: flex;
    margin: 0 auto;
}

.section--news .wrapper {
    margin-left: 10px;
    flex: 60%;
}

.section--news .news-title {
    font-size: var(--title-font-size);
    font-weight: 400;
    color: var(--base-text);
}

.section--news .news-subtitle {
    margin-top: 50px;
    font-size: var(--subtitle-font-size);
    font-weight: 300;
    color: var(--base-text);
}

.section--news .news-cards {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 20%);
    row-gap: 20px;
}

.section--news .news-desc {
    margin-top: 20px;
    font-size: var(--subtitle-font-size);
    color: var(--base-text);
    overflow-y: scroll;
    height: 10em;
}

.section--news .news-desc p {
    margin: 15px;
    font-weight: 300;
}

.section--news .news-image {
    flex: 40%;
    font-size: 300px;
    color: var(--news-image-color);
    align-self: center;
    text-align: center;
}

@media screen and (max-width: 1380px) {
    .section--news {
        padding: 90px 20px 0;
    }

    .section--news .news-title {
        font-size: var(--title-font-size-large);
    }

    .section--news .news-subtitle,
    .section--news .news-desc {
        font-size: var(--content-font-size);
    }

    .section--news .news-cards {
        grid-template-columns: repeat(auto-fit, 25%);
    }

    .section--news .news-image {
        font-size: 200px;
    }
}

@media screen and (max-width: 980px) {
    .section--news .news-cards {
        grid-template-columns: repeat(auto-fit, 32%);
    }
}

@media screen and (max-width: 768px) {
    .section--news .inner {
        display: block;
    }

    .section--news .news-title {
        font-size: var(--title-font-size-medium);
        text-align: center;
        font-weight: 500;
    }

    .section--news .news-subtitle {
        text-align: center;
    }

    .section--news .news-image {
        display: none;
    }

    .section--news .news-cards {
        grid-template-columns: repeat(auto-fit, 50%);
    }
}
