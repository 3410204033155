@import url("../../variables.css");

.rssfeed-card {
    border-radius: 3px;
    border-color: var(--border-color);
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    margin-bottom: -1px;
    color: var(--base-text);
    text-align: left;
    transition: 0.3s ease-out;
    width: 90%;
}

.rssfeed-card-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.rssfeed-card-pubDate {
    font-size: 15px;
    margin-bottom: 10px;
}
