@import url("../../variables.css");

.social-media-links {
    margin-top: 30px;
    font-size: 22px;
}
.social-media-links .icon-button i {
    padding: 10px;
    display: inline-block;
    color: var(--icon-button-color);
    box-sizing: border-box;
    border-radius: 50%;
    margin: 5px 5px;
    transition: 0.3s ease-out;
}
.email i {
    background-color: var(--gmail-color);
}
.twitter i {
    background-color: var(--x-color);
}
.shop i {
    background-color: var(--shop-color);
}
.homepage i {
    background-color: var(--homepage-color);
}
.link i {
    background-color: var(--link-color);
}
.icon-button > i:hover {
    background-color: var(--icon-background-color);
}
