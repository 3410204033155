@import url("../../variables.css");

.staffcard-main {
    border-radius: 10px;
    box-shadow: 13px 13px 6px var(--box-shadow-color);
    padding: 30px;
    margin-bottom: 30px;
    background-color: var(--card-background-color);
    border-style: solid;
    border-width: 10px;
    border-color: var(--border-color);
    color: var(--base-text);
    font-size: var(--subtitle-font-size);
    text-align: center;
    transition: 0.3s ease-out;
}
.staffcard-main:hover {
    box-shadow: 0 4px 8px var(--box-shadow-color-hover);
}
.staffcard-main .staffcard-role {
    font-weight: 500;
}
.staffcard-main .staffcard-company {
    margin-top: 30px;
}
.staffcard-main .staffcard-date {
    font-size: var(--content-font-size);
    margin-top: 30px;
    font-weight: 300;
    color: var(--base-text);
}

.staffcard-main .icon-button i {
    padding: 10px;
    display: inline-block;
    color: var(--icon-button-color);
    box-sizing: border-box;
    border-radius: 50%;
    margin: 5px 5px;
    transition: 0.3s ease-out;
}

.email i {
    background-color: var(--gmail-color);
}

.twitter i {
    background-color: var(--x-color);
}

.pixiv i {
    background-color: var(--pixiv-color);
}

.icon-button>i:hover {
    background-color: var(--icon-background-color);
}

@media screen and (max-width: 1380px) {
    .staffcard-main .staffcard-role {
        font-size: 22px;
    }
    .staffcard-main .staffcard-company {
        font-size: 19px;
    }
    .staffcard-main .staffcard-date {
        font-size: 16px;
    }
}
